import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MicRecorder from 'mic-recorder-to-mp3';

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const conversationStarters = [
    "What do you enjoy doing in your free time?",
    "Have you traveled anywhere interesting recently?",
    "What kind of music do you like to listen to?",
    "Do you have any favorite books or movies?",
    "What do you do for work or study?"
];

function getRandomQuestion(questions) {
    const randomIndex = Math.floor(Math.random() * questions.length);
    return questions[randomIndex];
}

const AudioRecorder = () => {
    const [isRecording, setIsRecording] = useState(false);
    const [blobURL, setBlobURL] = useState('');
    const [isBlocked, setIsBlocked] = useState(false);
    const [processedBlob, setProcessedBlob] = useState();
    const [showControls, setShowControls] = useState(false);
    const [transcribedText, setTranscribedText] = useState('');
    const [loading, setLoading] = useState(false);
    const [llmChat, setLlmChat] = useState('');
    const [restart, setRestart] = useState(true);
    const [question, setQuestion] = useState('');

    useEffect(() => {
        const randomQuestion = getRandomQuestion(conversationStarters);
        setQuestion(randomQuestion);
    }, []);

    navigator.mediaDevices.getUserMedia({ audio: true },
        () => {
            console.log('Permission Granted');
            setIsBlocked(false);
        },
        () => {
            console.log('Permission Denied');
            setIsBlocked(true);
        },
    );

    // useEffect(() => { console.log(llmChat) }, [llmChat]);



    const start = () => {
        setTranscribedText('')
        setLlmChat('')
        if (isBlocked) {
            console.log('Permission Denied');
        } else {
            Mp3Recorder
                .start()
                .then(() => {
                    setIsRecording(true);
                }).catch((e) => console.error(e));
        }
    };

    const stop = () => {
        setShowControls(true);
        Mp3Recorder
            .stop()
            .getMp3()
            .then(([buffer, blob]) => {
                const blobURL = URL.createObjectURL(blob)
                setBlobURL(blobURL);
                setIsRecording(false);
                // sendAudioToServer(blob);
                setProcessedBlob(blob)
            }).catch((e) => console.log(e));
    };

    const porcessSend = () => {
        setLoading(true);
        sendAudioToServer(processedBlob);
    }

    const llmchat = (transcribedText) => {
        console.log("Awaiting LLM chat.. ");
        axios.post('https://experiments.metadei.in/aidias_ai/aichat.php', {
            query: transcribedText
        })
            .then(response => {
                console.log(response.data.choices[0].message.content);
                setLlmChat(response.data.choices[0].message.content);
                setRestart(true);
                // You can handle the response from the second API here
            })
            .catch(error => {
                console.error(error);
            });
    };

    const sendAudioToServer = (audioBlob) => {
        setRestart(false);
        const formData = new FormData();
        formData.append('file', audioBlob, 'audio.mp3');
        console.log("Awaiting response")
        axios.post('https://experiments.metadei.in/aidias_ai/aitranscribe2.php', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        ).then(response => {
            // console.log("Awaiting response");
            console.log(response.data.text);
            setBlobURL('')
            setShowControls(false);
            setTranscribedText(response.data.text);
            llmchat(response.data.text)
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setBlobURL('')
            setLoading(false);
        });
        setBlobURL(null)
    };

    return (
        <div class="container">
            <div>
                <p>
                    Record Audio to Transcribe.
                    <br />
                    EN: GOOD, HI: AVG, BN: AVG
                </p>
                <br />
                <h2>{question}</h2>
                <p>Try answering in english to the best of your capability</p>
            </div>
            {/* <div>
                {
                    loading ?
                        <div>
                            <img src={require('./load.png')} width={60} alt="home" className='spinning center-loader' />
                        </div>
                        :
                        null
                }
            </div> */}
            <div>
                {/* {
                    transcribedText === '' ? null
                        : <p>{transcribedText}</p>
                } */}
                {transcribedText === '' ? null : <p>Your Input: {transcribedText}</p>}
                {llmChat === '' ? null : <><p>AI: </p><div dangerouslySetInnerHTML={{ __html: llmChat }} /></>}
            </div>
            <>
                {
                    restart ?
                        <>
                            <button onClick={isRecording ? stop : start} class="button start-button">
                                {isRecording ? 'Stop Recording' : 'Start Recording'}
                            </button>
                            {
                                showControls === false ? null :
                                    <audio src={blobURL} controls="controls" />
                            }
                            {
                                showControls === false ? null :
                                    <button onClick={porcessSend} class="button send-button">
                                        Send
                                    </button>
                            }
                        </>
                        :
                        <>
                            <img src={require('./ai.gif')} width={100} alt="home" className='center-loader' />
                        </>
                }

            </>
        </div >
    );
};

export default AudioRecorder;
